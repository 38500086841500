import React, {useEffect, useState, useMemo, useRef} from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'
import * as Tone from 'tone'
import {supplyRandomMathMusicPage} from "../randompage.js";
const PianoRoll =  loadable(() => import("../pianoroll/index.js"));

let synth;

let audioContext;
let oscList = [];
let mainGainNode = null;

let keyBoxes = [];
let keyNames = [];

const noteValues = {
  w1: 440,
  b1: 466.1638,
  w2: 493.883,
  b2: 523.2511,
  w3: 554.3653,
  w4: 587.3295,
  b3: 622.254,
  w5: 659.255,
  b4: 698.4565,
  w6: 739.9888,
  b5: 783.9909,
  w7: 830.6094,
  w8: 880,
  b6: 932.3275,
  w9: 987.7666,
  b7: 1046.502,
  w10: 1108.731,
  w11: 1174.659,
  b8: 1244.508,
  w12: 1318.51,
  b9: 1396.913,
  w13: 1479.978,
  b10: 1567.982,
  w14: 1661.219,
  w15: 1760
}
const playTone = (note) => {
  synth.triggerAttackRelease(noteValues[note], "4n");
}

export default function MathMusicNav ({frontmatter,fields,children}){
  let [mobileKey,setMobileKey] = useState(null);
  let mobileLinkStyle = useRef("black")
  let clicked = useRef(false);
  let hiddenInSafari = useRef([]);
  let [mobileNav,setMobileNav] = useState(false);
  let backgroundColor = frontmatter.background_color ? frontmatter.background_color : null;
  let backgroundStyle = frontmatter.background_style ? frontmatter.background_style : "cover";
  let backgroundImages = frontmatter.background_images ? frontmatter.background_images.map(e=>e.childImageSharp) : null;
  let images = []
  if(backgroundImages){
    images = images.concat(backgroundImages)
  }
  let [bg,setBg] = useState({})
  // setBg(images[0])

  const playNote = (note) => {
    console.log("playing ",note)
    mobileLinkStyle.current = `rgb(${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)})`;
    playTone(note);
  }

  const touchMove = (e) => {
    console.log(e)
    let pt = e.touches ? {x:e.touches[0].clientX,y:e.touches[0].clientY} : clicked.current ? {x: e.clientX,y:e.clientY} : {x:10000,y:10000};
    let hit = keyBoxes.findIndex(b => {
      let hitY = b.y < pt.y && b.y+b.height > pt.y;
      let hitX = b.x < pt.x && b.x+b.width > pt.x;
      return hitY && hitX;
    })
    let result = keyNames[hit];
    if(!result && result !== 0){
      result = null;
    }
    if(result){
      setMobileKey(result);
      playNote(result);
    }
  }

  const touchStart = (e,force) => {
    console.log(e,clicked.current)
    let pt = e.targetTouches ? {x:e.targetTouches[0].clientX,y:e.targetTouches[0].clientY} : clicked.current || force ? {x: e.clientX,y:e.clientY} : {x:10000,y:10000};
    let hit = keyBoxes.findIndex(b => {
      let hitY = b.y < pt.y && b.y+b.height > pt.y;
      let hitX = b.x < pt.x && b.x+b.width > pt.x;
      return hitY && hitX;
    })
    let result = keyNames[hit];
    if(!result && result !== 0){
      result = null;
    }
    console.log(result)
    if(result){
      setMobileKey(result);
      playNote(result);
    }
  }

  const initMobileKeyboard = async () => {
    await Tone.start()
    synth = new Tone.Synth().toDestination();
    setMobileNav(!mobileNav);
    document.querySelector("body").style.overflow = mobileNav ? "auto": "scroll";
    document.querySelector("html").style.overflow = mobileNav ? "auto": "hidden";

  }

  const PianoKey = (i,amt,idName,className) => {
    let pressed = mobileKey === idName+i;
    return <div id={`${idName}${i}`} key={i} onClick={touchStart}className={`mobile-key ${className} ${pressed ? "pressed" : ""}`}></div>
  }
  const pianoKeys = (amt,idName,className) => {
    let keys = [];
    for (var i = 1; i < amt+1; i++) {
      keys.push(PianoKey(i,amt,idName,className));
    }
    return keys;
  }
  const mobileLink = () => {

  }
  useEffect((e) => {
    if(images.length){
      setBg(images[Math.floor(Math.random()*images.length)])
    }

    if(typeof window !== "undefined"){
      if(window.innerWidth < 480){
        hiddenInSafari.current.push("/the-sun-will-never-rest/");
        //hiddenInSafari.current.push("/lectiones-elementares-mathematice/")
      }
      console.table(hiddenInSafari.current)
      let pianoKeys = document.querySelectorAll(".mobile-key")
      for (var i = 0; i < pianoKeys.length; i++) {
        let theKey = pianoKeys[i];
        keyNames.push(theKey.id);
        keyBoxes.push(theKey.getBoundingClientRect());
      }
      keyBoxes = keyBoxes.reverse();
      keyNames = keyNames.reverse();
      // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
      // hiddenInSafari = isSafari() ? ["mathmusic/the-sun-will-never-rest/"] : [];
    }
  },[])
  return (
    <StaticQuery
        query={graphql`
          query MathMusicQuery {
            allMarkdownRemark(
              filter: {fields: {table: {eq: "mathmusic"}}, frontmatter: {draft: {ne: true}}}
            ) {
              edges {
                node {
                  fields {
                    path
                    slug
                  }
                }
              }
            }
          }
        `}
        render={ data => {
          //hide tricky pages from safari
            let allLinks = data.allMarkdownRemark.edges.map(e => e.node.fields.slug).filter(p => {console.log(p); return hiddenInSafari.current.indexOf(p)===-1});
            console.log(allLinks)
            let links = ["/mathmusic"+supplyRandomMathMusicPage(fields.slug,"mathmusic",allLinks)]
            console.log(links)
            return <div id="mathmusic-nav" className={"page-wrapper page-"+(fields.slug ? fields.slug.slice(1,-1): "")}>
            <div className="page-bg">
              {bg.gatsbyImageData && <GatsbyImage object-fit="cover" image={getImage(bg.gatsbyImageData)} alt="" />}
            </div>
            {children}
            <PianoRoll links={links}/>
            <div id="pianoNav" className="mobile-only" onClick={initMobileKeyboard}></div>
            <div id="pianoPhone"
            onClick={e => touchStart(e,true)}
            onMouseUp={()=>{clicked.current = false; console.log(clicked)}}
            onMouseDown={()=>{clicked.current = true; console.log(clicked)}}
            className={`mobile-only mobile-keyboard keyboard-links ${mobileNav ? "open" : ""}`}>
              <div className="piano-spacer"></div>
              <div id="keyGrid" onTouchStart={touchStart} onMouseMove={touchMove} onMouseEnter={touchStart} onTouchMove={touchMove}>
                {pianoKeys(15,"w","iv")}
                {pianoKeys(10,"b","eb")}
              </div>
              <div id="keyLink" onClick={() => {
                if(typeof window !== "undefined"){
                  let choices = allLinks.filter(p => {console.log(p); return hiddenInSafari.current.indexOf(p)===-1});
                  let link = supplyRandomMathMusicPage(fields.slug,"mathmusic",allLinks,hiddenInSafari.current)
                  console.log(hiddenInSafari,link)
                  // let link = choices[Math.floor(Math.random()*choices.length)];
                  // console.log("LINK:",link)
                  window.location = "/mathmusic"+link;
                }}} style={{
                filter: `drop-shadow(0px 0px 10px ${mobileLinkStyle.current})`,
                background: `${mobileLinkStyle.current}`
              }}></div>
            </div>
          </div>

          }
        }
      />
    )
}
